/** By default, use the local font and then fallback to web font. **/
body {
  font-family: 'Segoe UI Local', 'Segoe UI Web (West European)';
}

/** In Chrome we can't use the semilight weight of the local web font. Use web fonts instead. **/
body:not(*:root) {
  font-family: 'Segoe UI Web (West European)';
}

.light {
  font-weight: 100;
}

.semilight {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.semibold {
  font-weight: 600;
}

@font-face {
  font-family: 'Segoe UI Local';
  src: local('Segoe UI Light');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Local';
  src: local('Segoe UI Semilight');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Local';
  src: local('Segoe UI');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Local';
  src: local('Segoe UI Semibold');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Web (West European)';
  src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff2') format('woff2'), url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Web (West European)';
  src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff2') format('woff2'), url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semilight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Web (West European)';
  src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff2') format('woff2'), url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI Web (West European)';
  src: url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff2') format('woff2'), url('https://static2.sharepointonline.com/files/fabric/assets/fonts/segoeui-westeuropean/segoeui-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}