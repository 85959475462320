
.fullpage{ 
  background: url(../images/globe.jpg) no-repeat right top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  /*max-height: 100vh;
  height: 100vh;*/
  min-height: 100vh;
}

.title{
	font-weight: 800;
	font-size: 3.75rem;
	line-height: 80px;
	letter-spacing: 0;
	color: #0073AB;
}

.subtitle{
	font-weight: bold;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #000000;
	text-align: center;
}

.subtitleContainer{
	margin-left: 16%;
	margin-right: 16%;
}

.colTitle{
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0;
	color: #0073AB;
}

.description{
	font-weight: 100;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #000000;
}

.rightColMargin{
	margin-right: 16%;
}

.leftColMargin{
	margin-left: 16%;
}

.noPadding{
	padding: 0px;
}

.rightimg{
	height: 100%;
	width: 90%;
}

.slideText{
	display:block;
	text-align: center;
	margin-top: 10px;
	font-family: "Segoe UI";
	font-weight: 300;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0;
	color: #ED6546;
}

.downArrows{
	position: absolute;
	/*bottom: 25px;*/
	bottom: 60px;
	left: 50%;
	max-height: 33px;
	transform: translateX(-50%);
}

.downArrows > img{
	max-height: 33px;
}

.sideArrowsRight{
	position: absolute;
	bottom: 50%;
	right: 10%;
	max-height: 33px;
	transform: translateY(50%) rotate(-90deg);
}

.sideArrowsLeft{
	position: absolute;
	bottom: 50%;
	left: 10%;
	max-height: 33px;
	transform: translateY(50%) rotate(90deg);
}

@media only screen and (max-width: 1500px) {
	.downArrows{
		bottom: 40px;
	}
}

@media only screen and (max-width: 1025px) {
	.title{
		font-weight: 800;
		font-size: 1.875rem;
		line-height: 35px;
		letter-spacing: 0;
		color: #0073AB;
	}
	.titleContainer{
		margin-left: 11%;
		margin-right: 11%;
	}
	.subtitle{
		text-align: left;
	}
	.reverseCol{
		flex-direction: column;
	}
	.subtitle{
		font-size: 0.9rem;
	}
	.rightColMargin{
		/*margin: 5% 2% 5% 2%;*/
		/*align-items: center;*/
		margin: 0px;
	}
	.rightimg{
		height: 100%;
		width: 80%;
	}
	.subtitleContainer{
		margin-left: 11%;
		margin-right: 11%;
	}
	.downArrows{
		bottom: 0px;
	}
	.fullpage{
		padding-bottom: 40px;
		 background-position: center center;
	}
	.leftColMargin{
		margin-right: 11%;
		margin-left: 11%;
	}
	.colMobile{
		padding: 0px;
	}
	.downArrows{
		position: absolute;
		bottom: 20px;
		transform: translateX(-50%);
	}
	.container{
		margin-bottom: 1.5rem;
	}
}

@media only screen and (min-width: 1025px) {
	.carroussoHeight{
		height: 197px;
	}
}

@media only screen and (min-width: 1600px) {
	.carroussoHeight{
		height: 300px;
	}
}

@media only screen and (min-width: 2000px) {
	.carroussoHeight{
		height: 350px;
	}
}