
.fullpage { 
  background: url(../images/aero_right.jpg) no-repeat left top fixed;
  /*background-position: right -250px top 10px;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  border-right: 7px solid #A76BF8;
  border-left: 7px solid #A76BF8;
}

.fullpageCar { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/automotive721x462.jpg) no-repeat center center fixed;
  background: -webkit-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/automotive721x462.jpg) no-repeat center center fixed;
  background: -moz-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/automotive721x462.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  /*border-right: 7px solid #ED6546;*/
  border-left: 7px solid #ED6546;
}

.fullpageCarVest { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/automotive721x462.jpg) no-repeat center center fixed;
  background: -webkit-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/automotive721x462.jpg) no-repeat center center fixed;
  background: -moz-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/automotive721x462.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  border-right: 7px solid #c5253d;
  border-left: 7px solid #c5253d;
}

.fullpageCarIGW { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/rail_721x462.jpg) no-repeat center center fixed;
  background: -webkit-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/rail_721x462.jpg) no-repeat center center fixed;
  background: -moz-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/rail_721x462.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  border-right: 7px solid #ED6546;
  border-left: 7px solid #ED6546;
}

.fullpageCarIGWI { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/industry72x462.jpg) no-repeat center center fixed;
  background: -webkit-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/industry72x462.jpg) no-repeat center center fixed;
  background: -moz-linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/industry72x462.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  border-right: 7px solid #fbb600;
  border-left: 7px solid #fbb600;
}

.fullpageOmco{
  background: transparent url(../images/OmcoMoulds_Application_02_3158x1800.jpg) center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  /*position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;*/		
}

.fullpageOmcoContainer{
	min-height: 100vh;
 	max-width: 100vw;
	overflow: hidden;
	border-right: 7px solid #00B4EA;
  	border-left: 7px solid #00B4EA;
  	z-index: 100;
}

.fullpageOmcoContainerMobile{
	background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/OmcoMoulds_Application_02_3158x1800.jpg) center center;
  	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
  	background-repeat: no-repeat;
	min-height: 100vh;
 	max-width: 100vw;
	overflow: hidden;
	/*border-right: 7px solid #00B4EA;
  	border-left: 7px solid #00B4EA;*/
  	position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;
  /*opacity: 0.2;*/
}

.fullpageAerospaceContainer{
	min-height: 100vh;
 	max-width: 100vw;
	overflow: hidden;
  	border-left: 7px solid #A76BF8;
  	z-index: 100;
}

.fullpageOmcoMarketsContainer, .fullpageAerospaceMarketsContainer{
	min-height: 100vh;
 	max-width: 100vw;
	overflow: hidden;
  	z-index: 100;
}

.fullpageOmcoProducts { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/omco_721x462.jpg) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  /*border-left: 7px solid #00B4EA;*/
  /*position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;*/
}

.fullpageAerospaceProducts { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/aero_right.jpg) no-repeat left top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  border-left: 7px solid #A76BF8;
  border-right: 7px solid #A76BF8;
  /*position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;*/
}

.fullpageOmcoMarkets { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/omco_721x462.jpg) no-repeat center top fixed;		
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  border-left: 7px solid #00B4EA;
  border-right: 7px solid #00B4EA;
  /*position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;*/
}

.fullpageAerospaceMarkets { 
  background: linear-gradient(238deg, rgba(12, 16, 51, 1), rgba(0, 115, 171, 0)) 0% 0% no-repeat, url(../images/aero_right.jpg) no-repeat left top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  max-width: 100vw;
  border-left: 7px solid #A76BF8;
  border-right: 7px solid #A76BF8;
  /*position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;*/
}

.fullpageNext { 
  background: url(../images/aero721x462.jpg) no-repeat right top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  border-right: 7px solid #A76BF8;
  border-left: 7px solid #A76BF8;
}

.fullpageIndustrial{
	background: url(../images/industry72x462.jpg) no-repeat right top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  /*position:fixed;
  top:0px;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;*/
}

.fullpageRail{
	background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/rail_721x462.jpg) no-repeat right top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
}

.fullpageContact { 
  background: url(../images/dottedmap.jpg) no-repeat center center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  position:fixed;
  top:16vh;
  right:0px;
  bottom:0px;
  left:0px;
  z-index: -100;
}

.title, .description, .features {
	color: white;
}

.blue{
	color: #0073AB!important;
}

.omcoblue{
	color: #00B4EA!important;
}

.dark{
	color: #0C1033!important;
}

.title{
	font-weight: bold;
	font-size: 60px;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.description{
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.features{
	font-weight: bold;
	font-size: 36px;
	line-height: 48px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.logo{
	height: 50px;
	position: absolute;
	/*bottom: 25px;*/
	bottom: 60px;
	/*left: 9.5%;*/
	right: 13.5%;
}

.scroll, .scrollNoBorder{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform: scale(0.7);
	height: 50px;
	right: 75px;
	z-index: 2;
}

.scrollNoBorder{
	right: 69px;
}

.scrollLeft{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform: scale(0.7);
	height: 50px;
	left: 75px;
	z-index: 2;
}

.scrollLeftNoBorder{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform: scale(0.7);
	height: 50px;
	left: 70px;
	z-index: 2;
}

.downArrows{
	position: absolute;
	/*bottom: 25px;*/
	bottom: 60px;
	right: 50%;
	max-height: 33px;
	transform: translateX(50%);
}

.upArrows{
	position: absolute;
	top: 95px;
	left: 50%;
	max-height: 33px;
	transform: rotate(180deg) translateX(50%);
	z-index: 1111;
}

.downArrows > img{
	max-height: 33px;
}

.refDownArrows{
	position: absolute;
	bottom: 65px;
	left: 47%;
	/*z-index: -1;*/
}

.refDownArrows > img{
	max-height: 33px;
	transform: translateX(50%);
}

.arrowsContainer{
	position: relative;
}

.leftColumn{
	margin-left: 8%;
	margin-top: 15vh;
}

.rightColumn{
	margin-right: 12%;
	margin-top: 15vh;
}

.leftColumn2{
	margin-left: 15%;
	margin-top: 15vh;
}

.rightColumn2{
	margin-right: 14%;
	margin-top: 22vh;
}

.rightColumn2 > img{
	width: 100%;
	height: 100%;
}

.leftColumnNext{
	margin-left: 15%;
	margin-top: 30vh;
}

.rightBlockContainer{
	/*position: fixed;*/
	position: absolute;
	right: 7px;
	top: -100px;
	width: 100vh;
}
.rightBlockContainer:hover{
	/*opacity: 0.6;*/
}

.rightBlockContainerNoBorder{
	/*position: fixed;*/
	position: absolute;
	top: -100px;
	width: 100vh;
	right: 0;
}
.rightBlockContainerNoBorder:hover{
	/*opacity: 0.6;*/
}

.rightBlock{
	-webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    transform-origin: right bottom;
    background: transparent linear-gradient(189deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
    height: 100px;
    cursor: pointer;
}

.rightBlockContent{
	font-weight: 300;
	font-size: 60px;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.rightBlockContent, a:hover {
	text-decoration: none!important;
}

.leftBlockContainer{
	/*position: fixed;*/
	position: absolute;
	left: 7px;
	top: calc(100vh - 100px);
	width: 100vh;
	margin-left: 100px;
}

.leftBlockContainerNoBorder{
	/*position: fixed;*/
	position: absolute;
	top: calc(100vh - 100px);
	width: 100vh;
	margin-left: 100px;
	left:0;
}

.leftBlockContainerNoBorder:hover{
	/*opacity: 0.6;*/
}

.leftBlock{
	-webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    transform-origin: left bottom;
    background: transparent linear-gradient(189deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
    height: 100px;
    cursor: pointer;
}

.orangeButtonText{
	font-weight: 300;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.orangeButton{
	background-color: #ED6546;
	border-radius: 25px;
	border-style: none;
	padding: 7px 25px;
	color: white;
}

.orangeButton:hover{
	color:#ED6546;
	background-color: white;
}

.blueButton{
	background-color: #00B4EA;
	border-radius: 25px;
	border-style: none;
	padding: 7px 25px;
	color: white;
}
.blueButton:hover{
	color:#00B4EA;
	background-color: white;
}

.purpleButton{
	background-color: #A76BF8;
	border-radius: 25px;
	border-style: none;
	padding: 7px 25px;
	color: white;
}
.purpleButton:hover{
	color:#A76BF8;
	background-color: white;
}

.yellowButton{
	background-color: #fbb600;
	border-radius: 25px;
	border-style: none;
	padding: 7px 25px;
	color: white;
}
.yellowButton:hover{
	color:#fbb600;
	background-color: white;
}

.bordeauButton{
	background-color: #c5253d;
	border-radius: 25px;
	border-style: none;
	padding: 7px 25px;
	color: white;
}
.bordeauButton:hover{
	color:#c5253d;
	background-color: white;
}

.slide1{
	flex: 30%;
	height: 10px;
	margin-top: 7px;
	margin-right: 5px;
	border-color: #ED6546;
	border-style: solid;
	border-width: 1px; 
	cursor: pointer;
}
.slide2{
	flex: 30%;
	height: 10px;
	margin-top: 7px;
	margin-right: 5px;
	border-color: #ED6546;
	border-style: solid;
	border-width: 1px; 
	cursor: pointer;
}
.slide3{
	flex: 30%;
	height: 10px;
	margin-top: 7px;
	border-color: #ED6546;
	border-style: solid;
	border-width: 1px; 
	cursor: pointer;
}
.slide1.active{
	background: #ED6546;
}
.slide2.active{
	background: #ED6546;
}
.slide3.active{
	background: #ED6546;
}

.slide1.blueSlide{
	border-color: #00B4EA!important;
}
.slide2.blueSlide{
	border-color: #00B4EA!important;
}
.slide3.blueSlide{
	border-color: #00B4EA!important;
}

.slide1.blueSlide.active{
	background: #00B4EA;
}
.slide2.blueSlide.active{
	background: #00B4EA;
}
.slide3.blueSlide.active{
	background: #00B4EA;
}

.slide1.purpleSlide{
	border-color: #A76BF8!important;
}
.slide2.purpleSlide{
	border-color: #A76BF8!important;
}
.slide3.purpleSlide{
	border-color: #A76BF8!important;
}

.slide1.purpleSlide.active{
	background: #A76BF8;
}
.slide2.purpleSlide.active{
	background: #A76BF8;
}
.slide3.purpleSlide.active{
	background: #A76BF8;
}

.slide1.greenSlide{
	border-color: #64AB80!important;
}
.slide2.greenSlide{
	border-color: #64AB80!important;
}
.slide3.greenSlide{
	border-color: #64AB80!important;
}

.slide1.greenSlide.active{
	background: #64AB80;
}
.slide2.greenSlide.active{
	background: #64AB80;
}
.slide3.greenSlide.active{
	background: #64AB80;
}

.slide1.yellowSlide{
	border-color: #fbb600!important;
}
.slide2.yellowSlide{
	border-color: #fbb600!important;
}
.slide3.yellowSlide{
	border-color: #fbb600!important;
}

.slide1.yellowSlide.active{
	background: #fbb600;
}
.slide2.yellowSlide.active{
	background: #fbb600;
}
.slide3.yellowSlide.active{
	background: #fbb600;
}

.slide1.bordeauSlide{
	border-color: #c5253d!important;
}
.slide2.bordeauSlide{
	border-color: #c5253d!important;
}
.slide3.bordeauSlide{
	border-color: #c5253d!important;
}

.slide1.bordeauSlide.active{
	background: #c5253d;
}
.slide2.bordeauSlide.active{
	background: #c5253d;
}
.slide3.bordeauSlide.active{
	background: #c5253d;
}

.flyer{
	position: absolute;
	right: 10vw;
	top: 35vh;
}
#containerForLayers {
  width: 100vw;
  height: 100vh;
  position: relative;
}
#layer1,
#layer2 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#layer2 {
  z-index: 10;
}

.referencesBackground{
	background: linear-gradient(238deg, rgba(12, 16, 51, 1), rgba(0, 115, 171, 1));
	/*height: 100vh;*/
	border-right: 7px solid #00B4EA;
  	border-left: 7px solid #00B4EA;
  	min-height: calc(100vh - 56px - 220px);
}

.refLogo{
	max-height: 89px;
	margin-bottom: 1.5rem !important;
}

.logoBoxT{
	max-height: 130px;
	max-width: 130px;
	min-height: 130px;
	min-width: 130px;
	background: white;
	/*margin-right: 4%;*/
}
.logoBox{
	max-height: 72px;
	min-height: 72px;
	max-width: 72px;
	background-color: white;
	margin-right: 10px;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
}

.logoBoxT{
	background:transparent;
}

.boxedLogo{
	height: auto;
	width: 100%;
	text-align: center;
	filter: grayscale(100%);
  	-webkit-filter: grayscale(100%);
  	-moz-filter: grayscale(100%);
  	transition: all 0.2s;
  	-webkit-transition: all 0.2s;
  	-moz-transition: all 0.2s;
}
.logoBox:hover {
  	filter: grayscale(0%);
  	-webkit-filter: grayscale(0%);
  	-moz-filter: grayscale(0%);
  	transform: scale(1.1);
  	-webkit-transform: scale(1.1);
  	-moz-transform: scale(1.1);
}
.logoBox:hover .boxedLogo, .logoBox.boxedLogo:hover{
	filter: grayscale(0%);
	-webkit-filter: grayscale(0%);
	-moz-filter: grayscale(0%);
}

.transparentLogo{
	height: 100%;
	width: 100%;
	min-width: 104px;
}

.logosWrapper{
	min-width: 80%;
	justify-content: center !important;
	margin-bottom: .5rem !important;
	margin-top: 0 !important;
}

.refBottomLogo{
	position: absolute;
	height: 50px;
	/*bottom: 25px;*/
	bottom: 60px;
	left: 8.3%;
}

.downloadButton{
	border-radius: 35px;
	border: 1px solid #ED6546;
	background: linear-gradient(to left, white 50%, #ED6546 50%);
    background-size: 200% 100%;
    background-position:right bottom;
	padding-right: 16px;
	padding-left: 15px;
	padding-top: 5px;
	padding-bottom: 5px;
	transition:all 1s ease;
	border-color: inherit;
  	-webkit-box-shadow: none;
  	box-shadow: none;
}
.downloadButton:hover{
	 background-position:left bottom;
}

.downloadButton.downloadButtonText:hover{
	color: white;
}

.downloadButtonText{
	font-weight: 300;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 0;
	color: #ED6546;
}

.nounderline {
  text-decoration: none !important
}

.downloadIcon{
	background-color: #ED6546;
	border-radius: 40px;
	padding: 5px;
}

.yearSlider{
	border: 1px solid #0C1033;
	border-radius: 35px;
	/*min-width: 34%;
	min-height: 10px;
	margin-top: 0px;
	position: relative;*/
}

.yearSlide{
	width: 100%;
	height: 15px;
	/*min-width: 11%;
	max-width: 10%;
	min-height: 10px;
	position: relative;
	left: 74%;*/ /* increments of 15% from the left for each year */
}

.yearSlide.active{
	border: 1px solid #ED6546;
	background-color: #ED6546;
	border-radius: 35px;
}

.yearSlide.nextyear{
	transform: translateX(137%); /* increments of 15% from the left for each year */
}

.year{
	font-weight: 400;
	font-size: 20px;
	line-height: 27px;
	letter-spacing: 0;
	color: #0C1033;
	margin-right: 12px;
	margin-left: 12px;
}
.activeYear{
	margin-right: 12px;
	margin-left: 12px;
	color: #ED6546;
}

.yearLink{
	text-decoration: none;
	color: #0C1033;
	position: relative;
}
.yearLink:hover{
	color: #0C1033;
}

.yearNote{
	font-weight: 300;
	font-size: 10px;
	line-height: 35px;
	letter-spacing: 0;
	color: #0C1033;
	position: absolute;
	bottom: 22px;
	min-width: 56px;
}

.visitWebsite{
	border-radius: 20px;
	border-width: 0px; 
	padding: 5px 10px 5px 10px;
	box-shadow: 0px 3px 6px #FFFFFF29;
	/*margin-top: 50px;*/
	background-color: white; 
	color: #0073AB;
}

.visitWebsite:hover{
	background-color: #0073AB; 
	color: white;
}

.contactFormText{ /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-weight: 300;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #0073AB;
}

.logoContainer{
	min-height: 100px;
	max-height: 184px;
	max-width: 100%;
	margin-bottom: 30px;
}

.globalMapContainer{
	background: transparent linear-gradient(240deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
  	background-attachment: fixed;
  	min-height: 100vh;
  	border-left: 7px solid #ED6546;
  	border-right: 7px solid #ED6546;
  	position:fixed;
  	top:0px;
  	right:0px;
  	bottom:0px;
  	left:0px;
  	z-index: -100;
}

.globalMapContainerIGWI{
	background: transparent linear-gradient(240deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
  	background-attachment: fixed;
  	min-height: 100vh;
  	border-left: 7px solid #fbb600;
  	border-right: 7px solid #fbb600;
  	position:fixed;
  	top:0px;
  	right:0px;
  	bottom:0px;
  	left:0px;
  	z-index: -100;
}

.globalMapContainerVCST{
	background: transparent linear-gradient(240deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
  	background-attachment: fixed;
  	min-height: 100vh;
  	border-left: 7px solid #c5253d;
  	border-right: 7px solid #c5253d;
  	position:fixed;
  	top:0px;
  	right:0px;
  	bottom:0px;
  	left:0px;
  	z-index: -100;
}

.globalMapContainerTransparent{
	background: transparent;
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
  	background-attachment: fixed;
  	min-height: 100vh;
  	/*position:fixed;
  	top:0px;
  	right:0px;
  	bottom:0px;
  	left:0px;
  	z-index: -100;*/
}

.blueBorder{
	border-left: 7px solid #00B4EA;
  	border-right: 7px solid #00B4EA;
}

.purpleBorder{
	border-left: 7px solid #A76BF8;
  	border-right: 7px solid #A76BF8;
}

.redBorder{
	border-left: 7px solid #ED6546;
  	border-right: 7px solid #ED6546;	
}

.yellowBorder{
	border-left: 7px solid #fbb600;
  	border-right: 7px solid #fbb600;	
}

.bordeauBorder{
	border-left: 7px solid #c5253d;
  	border-right: 7px solid #c5253d;	
}

.mapBox {
  display:inline-flex;
  vertical-align:top;
  justify-content:center;
  align-items:center;
}

.mapTooltip{
	visibility: visible;
    position: absolute;
    left: 0px;
    top: auto;
    margin: 0px;
    right: auto;
    bottom: 0px;
    /*transform: translate(211px, -295px);*/
    min-height: 100px;
    min-width: 150px;
    background-color: #00B4EA;
    opacity: 0%;
}

.mapTooltipText{
	font-weight: 200;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0;
	color: white;
	padding: 20px;
}

.slideTitle{
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0;
	color: #ED6546;
	padding-top: 5px;
	text-transform: capitalize;
}

.refButton{
	background: white;
	color: #0073AB;
	line-height: 20px;
	min-width: 286px;
	font-size: 0.9375rem;
	font-weight: 800;
}
.refButton:hover{
	color: #0073AB;
}

.brandCol{
	/*padding-left: 8px; 
    padding-right: 8px;*/
}

.backArrow{
	background-color: #ED6546;
	border-radius: 35px;
	height: 22px!important;
	transform: translateY(25%);
}
.nextArrow{
	background-color: #ED6546;
	border-radius: 35px;
	height: 22px!important;
	transform: translateY(25%);
}

.backArrow.purple{background-color: #A76BF8;}
.backArrow.blue{background-color: #00B4EA;}
.backArrow.yellow{background-color: #fbb600;}
.backArrow.bordeau{background-color: #c5253d;}

.nextArrow.purple{background-color: #A76BF8;}
.nextArrow.blue{background-color: #00B4EA;}
.nextArrow.yellow{background-color: #fbb600;}
.nextArrow.bordeau{background-color: #c5253d;}

.tooltipTitle{
	font-weight: bold;
	font-size: 1rem;
	line-height: 27px;
	letter-spacing: 0;
	color: white;
	padding-bottom: 10px;
}

@media only screen and (max-width: 2000px) and (min-width: 1400px) {

.rightColumn{
	margin-right: 16%;
	margin-top: 28vh;
}

}

@media only screen and (max-width: 2000px) and (min-width: 1600px) {
	/*.yearSlider{
		min-width: 24%;
	}*/
}

@media only screen and (max-width: 1600px) and (min-width: 1500px) {
	/*.yearSlider{
		min-width: 29%;
	}*/
}

@media only screen and (max-width: 1500px) and (min-width: 1400px) {
	/*.yearSlider{
		min-width: 32%;
	}*/
}

@media only screen and (max-width: 1400px) and (min-width: 1200px) {
.downArrows, .logo, .refDownArrows, .refBottomLogo{
	bottom: 25px;
}
}

@media only screen and (max-width: 1024px) {
	.secondRow{
		flex-direction: row!important;
		width: 80%;
	}
	.logosBranch{
		margin-top: 40px;
	}
	.logosBranchCol{
		margin: 10px 0px 10px 0px
	}
}

@media only screen and (max-width: 1024px) and (min-width: 1000px) {
	/*.yearSlider{
		min-width: 45%;
	}*/
}

@media only screen and (max-width: 1000px) and (min-width: 789px) {
	/*.yearSlider{
		min-width: 57%;
	}*/
}

@media only screen and (max-width: 500px) {
	.secondRow{
		flex-direction: column;
		align-items: center!important;
		margin: auto;
	}
	.logosBranch{
		margin-top: 40px;
	}
	.logosBranchCol{
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center!important;	
	}
	.logosBranchCol > img{
		margin: 10px 0px 10px 0px;
	}
	.websiteButton{
		margin: auto;
	}
	.logoBox{
		max-height: 100px;
		max-width: 100px;
		min-height: 100px;
		min-width: 100px;
		background: white;
		/*margin-right: 4%;*/
	}
}

@media only screen and (max-width: 791px) {
	.title{
		font-size: 1.875rem;
		margin: auto;
	}
	.slideTitle{
		font-size: 0.75rem;
	}
	.mould{
		width: 75%;
		flex-wrap: wrap;
		margin: auto;
	}
	.rowMobile{
		flex-direction: column;
	}
	.orangeButtonText{
		margin:auto;
	}
}

@media only screen and (max-width: 1024px) {
	.rightBlockContainer, .rightBlockContainerNoBorder, .leftBlockContainerNoBorder,
	.leftBlockContainer, .scroll, .scrollLeft, .scrollLeftNoBorder, .scrollNoBorder{
		display: none;
	}
	.fullpageCar{
		border-style: none;
		background: linear-gradient(209deg, rgba(12, 16, 51, 1) 0%, rgba(0, 115, 171, 1) 100%), url(../images/automotive721x462.jpg);
		background: -webkit-linear-gradient(209deg, rgba(12, 16, 51, 1) 0%, rgba(0, 115, 171, 1) 100%), url(../images/automotive721x462.jpg);
		background: -moz-linear-gradient(209deg, rgba(12, 16, 51, 1) 0%, rgba(0, 115, 171, 1) 100%), url(../images/automotive721x462.jpg);
		-webkit-background-size: cover;
  		-moz-background-size: cover;
  		-o-background-size: cover;
  		background-size: cover;
  		background-attachment: fixed;
  		background-position: center center;
  		min-height: calc(100vh - 56px);
	}
	.fullpageAerospaceProducts {
		background: linear-gradient(238deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)) 0% 0% no-repeat, url(../images/aero_right.jpg) no-repeat center center fixed;
	}
	.fullpage{
	    background: linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/aero_right.jpg);
	    background: -webkit-linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/aero_right.jpg);
	    background: -moz-linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/aero_right.jpg);
	    max-width: 100vw;
	    min-height: calc(100vh - 56px);
	    -webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    background-position: center center;
	    background-attachment: fixed;
	    z-index: -1;
	    border-style: none;
	  }
	.fullpageIndustrial{
		background-position: center center;
		opacity: 20%;
	}
	.fullpageAerospaceContainer{
	    background: linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/industry72x462.jpg);    
	    background: -webkit-linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/industry72x462.jpg);    
	    background: -moz-linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/industry72x462.jpg);
	    max-width: 100vw;
	    min-height: calc(100vh - 56px);
	    -webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    background-position: center center;
	    background-attachment: fixed;
	    z-index: -1;
	    border-style: none;
	  }
	.fullpageOmcoContainer{
	  	border-style: none;
	  	min-height: calc(100vh - 56px);
	  }
	.fullpageOmco{
		background: transparent url(../images/OmcoMoulds_Application_02_3158x1800.jpg) center center;
	  	background-repeat: no-repeat;
	  	min-height: calc(100vh - 56px);
	  	-webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    background-position: center center;
	    background-attachment: fixed;
	  	z-index: -100;

	}
	.fullpageOmcoMarkets{
		border-style: none;
	}
	.fullpageOmcoProducts{
		border-style: none;
	}
	.leftColumn, .leftColumn2, .rightColumn, .rightColumn2{
		margin: 0px;
		padding: 1px;
	}
	.description{
		width: 75%!important;
		margin: auto;
	}
	.rightimg{
		width: 75%;
		margin: auto;
	}
	.genericPageContainer{
		padding-left: 37px;
		padding-right: 37px;	
	}
	.rowMobile{
		margin: 0px;
	}
	.orangeButton{
		padding: 7px 25px;
		min-width: 100%;
	}
	.downArrowsMobile > img{
    	max-height: 27px;
  	}
  	.cfm{
    	/*min-height: calc(100vh - 56px);*/
    	min-height: 100vh;
  	}
  	.genericPageBottomContainer{
  		margin-bottom: 1rem;
  	}
  	.genericPageArrows{
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
	}
	.genericPageLogo{
		max-width: 80%;
	}
	.downArrows{
		bottom: 20px;
		transform: translateX(50%) translateY(-50%);
	}
	.logo{
		bottom: 20px;
	}
	.features{
    	margin-right: 20px!important;
    	font-size: 26px;
  	}
  	.featuresLast{
    	margin-right: 0px!important;
  	}
  	.referencesBackground{
  		border-style: none;
  		min-height: calc(100vh - 56px);
  	}
}

/*iPad in portrait & landscape*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  
{
	.genericPageBottomContainer{
		margin-bottom: 1rem;
	}
  	.genericPageArrows{
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
	}
	.genericPageLogo{
		max-width: 60%;
	}
	.genericPageArrows > img{
		max-height: 33px;
	}
}

/*iPad Pro in portrait & landscape*/
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px)  
{
	.genericPageBottomContainer{
		margin-bottom: 0.8rem;
	}
	.genericPageArrows{
		position: absolute;
		transform: translateX(-50%) translateY(-100%);
	}
	.genericPageLogo{
		max-width: 60%;
		transform: translateY(-50%);
	}
	.genericPageArrows > img{
		max-height: 33px;
	}
}
.carouselControl{
    pointer-events: none;
}

@media only screen and (max-width: 415px) {
	.description, .rightimg{
		width: 83%!important;
		margin: unset;
		margin-left: auto;
	}
	.description p{
		color: white!important;
	}
	.orangeButtonText{
		font-size: 16px;
	}
	.websiteButton{
		margin-left: 2rem;
	}

	.logoBox{
		height: 100px;
		overflow: hidden;
		width: 100px;
		margin-left: 10px;
	}
	.boxedLogo{
		height: auto;
		width: 75%;
		margin:auto !important;
	}
}