.title{
	font-weight: bold;
	font-size: 60px;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.colLogo{
	max-height: 89px;

}

.logoBox{
	max-height: 72px;
	min-height: 72px;
	max-width: 72px;
	background-color: white;
	margin-right: 10px;
	transition: all 0.2s;
	-webkit-transition: all 0.2s;
	-moz-transition: all 0.2s;
}
.logoBox:hover{
	filter: grayscale(0%);
	-webkit-filter: grayscale(0%);
	-moz-filter: grayscale(0%);
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-moz-transform: scale(1.1);
}

.refDownArrows{
	position: relative;
	bottom: 15px;
	left: 30%;
}

.refDownArrows > img{
	max-height: 33px;
	transform: translateX(100%);
}

.refBottomLogo{
	position: relative;
	height: 50px;
	bottom: 25px;
	left: 8.3%;
}

@media only screen and (min-width: 1400px) {
  .refDownArrows{
	left: 35%;
	}
}

@media only screen and (max-width: 1025px) {
	.title{
		font-weight: bold;
	font-size: 2.5rem;
	line-height: 53px;
	letter-spacing: 0;
	color: #FFFFFF;
	}
	.logoBox{
		max-height: 64px;
		min-height: 64px;
		max-width: 64px;
		background-color: white;
	}
}


