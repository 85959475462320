
.adjustFunc{
	/*padding-left: 0px;*/
}

.contactSend{
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0;
	color: #FFFFFF;
	background-color: #0073AB;
	box-shadow: 0px 0px 10px #0000001A;
	padding: 7px 10px 7px 10px;
	border-width: 0px;
}
.contactSend:hover{
	color: #0073AB;
	background-color: #FFFFFF;
}

.contactAddressTitle{
	font-weight: bold;
	font-size: 30px;
	line-height: 40px;
	letter-spacing: 0;
	color: #0073AB;
}

.contactAddressInfo{
	font-weight: 300;
	font-size: 1rem;
	line-height: 21px;
	letter-spacing: 0;
	color: #000000;
}

.contactAddressInfo:hover{
	text-decoration: none;
	color: #000000;	
}

.branches{
	margin: 0px 10% 0px 10%;
}

.reach{
	display: none;
}

.reachImg{
	max-width: 60px;
}

.fullImg{
	width: 100%
}

.formStyle{
	margin: 0px 15% 0px 15%;
}

.contact{
	justify-content: center;
	margin-top: 100px!important;
}

.contactInfo{
	margin: 0px 15% 0px 15%;
}

.departaments{
	margin: 0px 20% 0px 20%;
}

.containerIpad{
	min-height: 100vh;
}

@media only screen and (max-width: 991px) {
	.contact{
		margin-top: 0px;	
	}
	.toColumn{
		flex-direction: column;
	}
	.adjustComp{
		margin-bottom: 16px;
	}
	.adjustFunc{
		padding-left: 13px;
	}
	.contactSend{
		padding: 7px 25px 7px 25px;
	}
	.contactAddressTitle{
		font-size: 0.875rem;
	}
	.contactAddressInfo{
		font-size: 0.8125rem;
	}
	.branches{
		/*display: none!important;*/
	}
	.reach{
		display: block!important;
		padding-left: 15px;
		padding-right: 15px;
	}
	.formStyle{
		margin: 0px;
	}
	.contact{
		padding-left: 15px;
		justify-content: flex-start!important;
	}
	.contactInfo{
		display: none;
	}
	.departaments{
		margin: 0px;
		padding-left: 15px;
		padding-right: 15px;
	}
	.companyName{
		font-weight: 600;
		font-size: 20px;
		line-height: 27px;
		letter-spacing: 0;
		color: #000000;
	}
	.contactAddressTitle{
		font-weight: 600;
	}
	.logo1{
		/*width: 98px;
    	height: 61px;*/
    	width: 85%;
    	height: 100%;
	}
	.logo2{
		width: 83px;
    	height: 61px;
	}
	.logo3{
		width: 84px;
    	height: 60px;
	}
}

/*iPad in portrait & landscape*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  
{
	.colIpad{
		display: flex;
		justify-content: center;
	}
}

/*iPad Pro in portrait & landscape*/
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px)
{
	.containerIpad{
		min-height: calc(100vh - 56px - 220px);
	}
}
