.fullpage { 
  background: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(237, 101, 70, 1)) 0% 0% no-repeat;
  min-height: 100vh;
  border-left: 7px solid #ED6546;
  border-right: 7px solid #ED6546;
}

.fullpageIGWI{
  background: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(251, 182, 0, 1)) 0% 0% no-repeat;
  min-height: 100vh;
  border-left: 7px solid #fbb600;
  border-right: 7px solid #fbb600;
}

.fullpageVCST{
  background: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(197, 37, 61, 1)) 0% 0% no-repeat;
  min-height: 100vh;
  border-left: 7px solid #c5253d;
  border-right: 7px solid #c5253d;
}

.backgroundImg{
  background-image: url(../images/automotive721x462.jpg);
  background: linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/automotive721x462.jpg);
  background: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/automotive721x462.jpg);
  background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/automotive721x462.jpg);  
  background-origin: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 20%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  opacity: 0.75;
}

.backgroundImgIGWI{
  background-image: url(../images/industry72x462.jpg);
  background: linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(162, 140, 27, 100%)), url(../images/industry72x462.jpg);
  background: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(162, 140, 27, 100%)), url(../images/industry72x462.jpg);
  background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(162, 140, 27, 100%)), url(../images/industry72x462.jpg);  
  background-origin: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 20%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  opacity: 0.75;
}

.backgroundImgIGW{
  background-image: url(../images/rail_721x462.jpg);
  background: linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);
  background: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);
  background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);  
  background-origin: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 20%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  opacity: 0.75;
}

.backgroundImgVCST{
  background-image: url(../images/automotive721x462.jpg);
  background: linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(197, 37, 61, 100%)), url(../images/automotive721x462.jpg);
  background: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(197, 37, 61, 100%)), url(../images/automotive721x462.jpg);
  background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(197, 37, 61, 100%)), url(../images/automotive721x462.jpg);  
  background-origin: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: 50% 20%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: 100vh;
  opacity: 0.75;
}

.logo{
  /*left: 50%;
  position: absolute;
  top: 40%;
  height: 150px;
  width: 600px;
  transform: translateX(-50%);*/
  max-width: 20%;
}

.logoAbs{
  left: 50%;
  position: absolute;
  top: 50%;
  max-width: 80%;
  transform: translateX(-50%) translateY(-50%);
}

.downArrows{
  position: absolute;
  bottom: 10px;
  right: 50%;
  max-height: 33px;
  transform: translateX(50%);
}

.minHeight{
  min-height: calc(100vh - 56px);
}

.subtitle{
  font-weight: 400;
  font-size: 41px;
  line-height: 54px;
  letter-spacing: 0;
  color: #FFFFFF;
  max-width: 30vw;
  text-align: center;
}

.upArrows{
  position: absolute;
  top: 95px;
  left: 50%;
  max-height: 33px;
  transform: rotate(180deg) translateX(50%);
  z-index: 1111;
}

.upArrowsMob{
  max-height: 33px;
  transform: rotate(180deg) translateX(50%);
  z-index: 1111;
}

@media only screen and (max-width: 991px) {
  .fullpage { 
    background: transparent linear-gradient(209deg, rgba(12, 16, 51, 1) 0%, rgba(237, 101, 70, 1) 100%);    
    background: transparent -webkit-linear-gradient(209deg, rgba(12, 16, 51, 1) 0%, rgba(237, 101, 70, 1) 100%);    
    background: transparent -moz-linear-gradient(209deg, rgba(12, 16, 51, 1) 0%, rgba(237, 101, 70, 1) 100%);    
    min-height: calc(100vh - 56px);
    border-style: none;
  }
  .backgroundImg{
    min-height: 100vh;
  }
  .downArrows{
    max-height: 27px;
    bottom: 20px;
  }
  .downArrowsMob{
    max-height: 27px;
    bottom: 20px;
  }
  .logo{
    max-width: 40% !important;
  }

  .subtitle{
    width: 80%;
    font-size: 34px;
    max-width: 100vw;
  }

  .logoAbs{
    left: 50%;
    position: absolute;
    top: 20%;
    max-width: 30%;
    transform: translateX(-50%);
  }

  .logoAbsMob{
    max-width: 40%;
  }
}