
.fullpage { 
  background: linear-gradient(to top right, rgba(12, 16, 51, 0), rgba(0, 180, 234, 1) ) 0% 0% no-repeat, url(../images/omco_721x462.jpg) no-repeat center top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;

}

.container{
	position: relative;
}

.logo{
	/*height: 100px;*/
	width: 183px;
}

.downArrows{
	position: fixed;
	top: 90vh;
	right: 50vw;
	transform: translateX(50%);
	max-height: 33px;
}

.downArrows > img{
	max-height: 33px;
}

.subtitle{
	font-weight: 400;
	font-size: 41px;
	line-height: 54px;
	letter-spacing: 0;
	color: #FFFFFF;
	/*max-width: 25vw;*/
    text-align: center;
}

.upArrows{
	position: absolute;
	top: 95px;
	left: 50%;
	max-height: 33px;
	transform: rotate(180deg) translateX(50%);
	z-index: 1111;
}

.upArrowsMob{
	/*position: absolute;
	top: 95px;
	left: 50%;
	transform: rotate(180deg) translateX(50%);*/
	max-height: 33px;
	z-index: 1111;
}

.downArrowsMob{
	max-height: 33px;
}

@media only screen and (max-width: 500px) {
	.logo{
		max-width: 40%;
	}

}