
.topSection{
	background: transparent linear-gradient(249deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
	/*height: 419px;*/
}

.logo{
	max-width: 537px;
	max-height: 90px;
	margin-top: 50px;
}

.logoCol{
	max-height: 40%;
	max-width: 40%;
}

.logoColDrive{
	max-width: 60%;
}

.logoColContact{
	max-height: 70%;
	max-width: 60%;
}

.textSection{
	max-width: 82%;
	margin-top: 40px;
  	font-weight: 500;
  	/*font-size: 30px;*/
  	font-size: 1.5625rem;
  	line-height: 44px;
  	letter-spacing: 0;
  	color: #FFFFFF;
  	text-align: center;
}

.downArrows{
	margin-top: 20px;
	max-height: 33px;
	margin-bottom: 20px;
}

.columnBrand {
  	flex: 33.33%;
  	align-self: flex-start;
  	height: 281px;
  	flex-grow: 0;
}

.column1{
	background-image: url(../images/aero721x462.jpg);
	background-image: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(167, 107, 248, 1)),url(../images/aero721x462.jpg);
    background-image: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(167, 107, 248, 1)), url(../images/aero721x462.jpg);
  	background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(167, 107, 248, 1)), url(../images/aero721x462.jpg);  	
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
	opacity: 0.75;
	min-height: 100%;
	/*margin-right: 5px;*/
}

.column1:hover .chill {
    transform: scale(1.3);
}

.chill {
    background-repeat: no-repeat;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.column2{
	background-image: url(../images/automotive721x462.jpg);
	background-image: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(197, 37, 61, 1)), url(../images/automotive721x462.jpg);
	background-image: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(197, 37, 61, 1)), url(../images/automotive721x462.jpg);
  	background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(197, 37, 61, 1)), url(../images/automotive721x462.jpg);  	
	background-position: center center;
	opacity: 0.75;
	/*margin-right: 5px;*/
	background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}
.column2:hover .chill {
    transform: scale(1.3);
}

.column3{
	/*background-image: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)), url(../images/balls.png);
	background-image: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(0, 115, 171, 1)), url(../images/balls.png);*/
	background-image: url(../images/omco_721x462.jpg);
  	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);  
	background-position: center center;	
	/*opacity: 0.75;*/
	background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}
.column3:hover .chill {
    transform: scale(1.3);
}

.column4{
	background-image: url(../images/industry72x462.jpg);
  	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);  
	background-position: center center;	
	background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}
.column4:hover .chill {
    transform: scale(1.3);
}

.column4Colorized{
	background-image: url(../images/industry72x462.jpg);
  	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(251, 182, 0, 100%)), url(../images/industry72x462.jpg);
  	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(251, 182, 0, 100%)), url(../images/industry72x462.jpg);
  	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(251, 182, 0 100%)), url(../images/industry72x462.jpg);  
	background-position: center center;	
	background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}
.column4Colorized:hover .chill {
    transform: scale(1.3);
}

.column5{
	background-image: url(../images/rail_721x462.jpg);
  	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);
  	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);
  	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);  
	background-position: center center;	
	background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}
.column5:hover .chill {
    transform: scale(1.3);
}

.column5Colorized{
	background-image: url(../images/rail_721x462.jpg);
  	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);
  	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);
  	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/rail_721x462.jpg);  
	background-position: center center;	
	background-repeat: no-repeat;
    background-size: cover;
    min-height: 100%;
}
.column5Colorized:hover .chill {
    transform: scale(1.3);
}

.hoverImg:hover .child {
    transform: scale(1.1);
}
.hoverImg{
    overflow: hidden;
    padding: 0 !important;
}

.hoverImgPadding:hover .child {
    transform: scale(1.1);
}
.hoverImgPadding{
    overflow: hidden;
    padding: 0 5px 0 5px !important;
}

.child {
    background-repeat: no-repeat;
    -webkit-transition: all .5s;
    -moz-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

@media only screen and (max-width: 770px){
	.logo{
		max-width: 90%;
	}
	.logoCol{
		max-height: 50%;
		max-width: 36%;
	}
	.column1,.column2,.column3{
		min-height: 174px;
	}
	.logoColDrive{

	}
	.textSection{
		font-size: 1.3rem;
	}
	.hoverImgPadding{
		padding: 0px!important;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 771px){
	.textSection{
		font-size: 1.3rem;
	}
}