
.fullpage { 
  background: linear-gradient(242deg, rgba(12, 16, 51, 0), rgba(167, 107, 248, 1)) 0% 0% no-repeat, url(../images/aero721x462.jpg) no-repeat left top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;

}

.container{
	position: relative;
}

.logo{
	height: 100px;
}
.rightimg{
	width: 70%;
}

.downArrows{
	position: absolute;
	/*bottom: -70%;
	right: 49%;*/
	max-height: 33px;
	bottom: 25px;
	right: 50%;
	transform: translateX(50%);
}

.title{
	font-weight: 100;
	font-size: 42px;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.subtitleContainer{
	margin-top: 21px;
}

.subtitle{
	font-weight: 400;
	font-size: 30px;
	line-height: 35px;
	letter-spacing: 0;
	color: #FFFFFF;
	max-width: 25vw;
    text-align: center;
}

.upArrows{
	position: absolute;
	top: 100px;
	left: 50%;
	max-height: 33px;
	transform: rotate(180deg) translateX(50%);
	z-index: 1111;
}

.downArrowsMob{
	max-height: 33px;
	margin-top: 20px;
}

@media only screen and (max-width: 991px){
	.subtitle{
		max-width: 50%;
	}
}

