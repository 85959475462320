.vertical {
  font-size: 14px;
  letter-spacing: 0.025rem;
  color: #fff;
  position: absolute;
  bottom: 10vh;
  left: 2rem;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  color: rgba(255,255,255,0.5);
  z-index: 1111;
}
.vertical a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  /*display: inline-block;*/
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}
.vertical a:hover {
  color: #fc0;
}
.vertical a:hover:after {
  color: rgba(255,255,255,0.5);
}
.vertical a:after {
  /*content: '/';*/
  padding: 0 0.5rem;
  display: inline-block;
}
.vertical span.destination {
  color: #fff;
}

.vertical2 {
  font-size: 14px;
  letter-spacing: 0.025rem;
  color: #0073AB!important;
  position: absolute;
  top: 80vh;
  left: 2rem;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  color: rgba(255,255,255,0.5);
  z-index: 1111;
}
.vertical2 a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  /*display: inline-block;*/
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}
.vertical2 a:hover {
  color: #fc0;
}
.vertical2 a:hover:after {
  color: rgba(255,255,255,0.5);
}
.vertical2 a:after {
  /*content: '/';*/
  padding: 0 0.5rem;
  display: inline-block;
}
.vertical2 span.destination {
  color: #000;
}

.vertical3 {
  font-size: 14px;
  letter-spacing: 0.025rem;
  color: #0073AB!important;
  position: absolute;
  bottom: 30vh;
  left: 2rem;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  color: rgba(255,255,255,0.5);
  z-index: 1111;
}
.vertical3 a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  /*display: inline-block;*/
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}
.vertical3 a:hover {
  color: #fc0;
}
.vertical3 a:hover:after {
  color: rgba(255,255,255,0.5);
}
.vertical3 a:after {
  /*content: '/';*/
  padding: 0 0.5rem;
  display: inline-block;
}
.vertical3 span.destination {
  color: #000;
}