
.fullpage { 
  background: url(../images/globe.jpg) no-repeat right top fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
  overflow: hidden;
}

.downArrows{
  position: relative;
  bottom: 25px;
  right: -50%;
}

.upArrows{
  position: absolute;
  top: 95px;
  left: 50%;
  max-height: 33px;
  transform: rotate(180deg) translateX(50%);
  z-index: 1111;
}

.titleContainer{
  margin-top: 9vh;
}

.title{
  font-weight: bold;
  font-size: 60px;
  line-height: 80px;
  letter-spacing: 0;
  color: #0073AB;
}

.cardtitle{
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0;
  color: #0073AB;
}

.carddescription{
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0;
  color: #0C1033;
}

hr{
  border: 2px solid #BEC0C2;
}

@media only screen and (max-width: 1025px){
  .titleContainer{
    margin-top: 5vh;
  }
  .title{
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0;
    color: #0073AB;
  }
  .fullpage{
    background-image: none;
    min-height: calc(100vh - 56px);
  }
  .downArrowsMobile > img{
    max-height: 27px;
  }
}