#arrowClassic,
#arrowClassic::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

#arrowClassic::before {
  content: '';
  transform: rotate(45deg);
  background: #333;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 3px;
  height: 10px;
}

#arrow::before {
  content: '';
  background: #00B4EA;
  transform: translateY(-100%) rotate(0deg);
  /*transform: translateY(-50%) rotate(45deg);*/
}

#arrowRedReverse,
#arrowRedReverse::before {
  position: absolute;
  right: -6px;
  width: 3px;
  height: 60px;
}

#arrowRedReverse::before {
  content: '';
  background: #ED6546;
  transform: translateY(33%) rotate(90deg);
  /*transform: translateY(-50%) rotate(45deg);*/
}

#arrowRedMutiple,
#arrowRedMultiple::before {
  position: absolute;
  width: 3px;
  height: 80px;
}

#arrowRedMultiple{
  transform: translate(-3px, 139px)!important;
}

#arrowRedMultiple::before {
  content: '';
  background: #ED6546;
  transform: translateY(-50%) rotate(90deg);
}

#arrowRedOne,
#arrowRedOne::before {
  position: absolute;
  left: 50%;
  width: 3px;
  height: 10px;
}

#arrowRedOne::before {
  content: '';
  background: #ED6546;
  transform: translateY(-100%) translateX(-100%) rotate(0deg);
}

#arrowPurpleOne,
#arrowPurpleOne::before {
  position: absolute;
  left: 50%;
  width: 3px;
  height: 10px;
}

#arrowPurpleOne::before {
  content: '';
  background: #A76BF8;
  transform: translateY(-100%) translateX(-100%) rotate(0deg);
}

#arrowBlueOne,
#arrowBlueOne::before {
  position: absolute;
  left: 50%;
  width: 3px;
  height: 10px;
}

#arrowBlueOne::before {
  content: '';
  background: #00B4EA;
  transform: translateY(-100%) translateX(-100%) rotate(0deg);
}

#arrowPurpleMutiple,
#arrowPurpleMultiple::after {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 3px;
  height: 70px;
}

#arrowPurpleMultiple{
  transform: translate(255px, 139px)!important;
}

#arrowPurpleMultiple::after {
  content: '';
  background: #A76BF8;
  /*background: black;*/
  transform: translateY(-50%) translateX(100%) rotate(90deg);
}

#arrowPurpleReverse,
#arrowPurpleReverse::before {
  position: absolute;
  left: -4px;
  width: 3px;
  height: 70px;
}

#arrowPurpleReverse{
  transform: translate(0px, 80px)!important;
}

#arrowPurpleReverse::before {
  content: '';
  background: #A76BF8;
  transform: translateY(36%) rotate(90deg);
}

@media only screen and (max-width: 1366px) {
  #arrow, #arrowRedOne, #arrowPurpleOne, #arrowBlueOne {
    display: none
  }
}