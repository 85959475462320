.fullpage { 
  background: url(../images/aero_right.jpg) no-repeat left top fixed;
  /*background-position: right -250px top 10px;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  border-right: 7px solid #A76BF8;
  border-left: 7px solid #A76BF8;
}

.fullpageNext { 
  background: url(../images/aero_right.jpg) no-repeat left top fixed;
  /*background-position: right -50px top 0px;*/
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100vh;
  border-right: 7px solid #A76BF8;
  border-left: 7px solid #A76BF8;
}

.title, .description, .features {
	color: white;
}

.title{
	font-weight: bold;
	font-size: 60px;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.description{
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.features{
	font-weight: bold;
	font-size: 36px;
	line-height: 48px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.logo{
	height: 50px;
	position: absolute;
	bottom: 25px;
	left: 9.5%;
}

.downArrows{
	position: absolute;
	bottom: 25px;
	right: 50%;
}

.arrowsContainer{
	position: relative;
}

.leftColumn{
	margin-left: 8%;
	margin-top: 15vh;
}

.leftColumn2{
	margin-left: 15%;
	margin-top: 15vh;
}

.leftColumnNext{
	margin-left: 15%;
	margin-top: 30vh;
}

.rightBlockContainer{
	position: absolute;
	right: 7px;
	top: -100px;
	width: 100vh;
}

.rightBlock{
	-webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    transform-origin: right bottom;
    background: transparent linear-gradient(189deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
    height: 100px;
    cursor: pointer;
}

.rightBlockContent{
	font-weight: 300;
	font-size: 60px;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.rightBlockContent, a:hover {
	text-decoration: none!important;
}

.leftBlockContainer{
	/*position: fixed;*/
	position: absolute;
	left: 7px;
	top: calc(100vh - 100px);
	width: 100vh;
	margin-left: 100px;
}

.leftBlockContainer:hover{
	/*opacity: 0.6;*/
}

.leftBlock{
	-webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
    transform-origin: left bottom;
    background: transparent linear-gradient(189deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
    height: 100px;
    cursor: pointer;
}

.scrollLeft{
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform: scale(0.7);
	height: 50px;
	left: 75px;
	z-index: 2;
}

.backArrow{
	background-color: #ED6546;
	border-radius: 35px;
	height: 22px;
	transform: translateY(25%);
}

.nextArrow{
	background-color: #ED6546;
	border-radius: 35px;
	height: 22px;
	transform: translateY(25%);
}

.backArrow.purple{background-color: #A76BF8;}
.backArrow.blue{background-color: #00B4EA;}

.nextArrow.purple{background-color: #A76BF8;}
.nextArrow.blue{background-color: #00B4EA;}

@media only screen and (max-width: 1024px) {
	.rightBlockContainer, .rightBlockContainerNoBorder, .leftBlockContainerNoBorder,
	.leftBlockContainer, .scroll, .scrollLeft, .scrollLeftNoBorder{
		display: none;
	}
	.fullpageNext{
	    background: linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/aero721x462.jpg);    
	    background: -webkit-linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/aero721x462.jpg);    
	    background: -moz-linear-gradient(0deg, rgba(12, 16, 51, 0%), rgba(0, 0, 0, 100%)), url(../images/aero721x462.jpg);
	    max-width: 100vw;
	    min-height: calc(100vh - 56px);
	    -webkit-background-size: cover;
	    -moz-background-size: cover;
	    -o-background-size: cover;
	    background-size: cover;
	    background-position: center center;
	    background-attachment: fixed;
	    z-index: -1;
	    border-style: none;
	  }
	.leftColumn, .rightColumn{
		margin: 0px;
		padding: 1px;
	}
	.description{
		width: 100%!important;
	}
	.genericPageContainer{
		padding-left: 37px;
		padding-right: 37px;	
	}
	.rowMobile{
		margin: 0px;
	}
	.orangeButton{
		padding: 7px 25px;
		min-width: 100%;
	}
	.downArrowsMobile > img{
    	max-height: 27px;
  	}
  	.cfm{
    	/*min-height: calc(100vh - 56px);*/
    	min-height: 100vh;
  	}
  	.genericPageBottomContainer{
  		margin-bottom: 1rem;
  	}
  	.genericPageArrows{
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
	}
	.genericPageLogo{
		max-width: 80%;
	}
}

/*iPad in portrait & landscape*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  
{
	.genericPageBottomContainer{
		margin-bottom: 1rem;
	}
  	.genericPageArrows{
		position: absolute;
		transform: translateX(-50%) translateY(-50%);
	}
	.genericPageLogo{
		max-width: 60%;
	}
	.genericPageArrows > img{
		max-height: 33px;
	}
}

/*iPad Pro in portrait & landscape*/
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px)  
{
	.genericPageBottomContainer{
		margin-bottom: 0.8rem;
	}
	.genericPageArrows{
		position: absolute;
		transform: translateX(-50%) translateY(-100%);
	}
	.genericPageLogo{
		max-width: 60%;
		transform: translateY(-50%);
	}
	.genericPageArrows > img{
		max-height: 33px;
	}
}

@media only screen and (max-width: 791px) {
	.title{
		font-size: 1.875rem;
	}
	.slideTitle{
		font-size: 0.75rem;
	}	
}