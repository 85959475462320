.navbar{
	background-color: white;
}

.navbar-light .navbar-toggler {
	border-style: none;
}

.navbar-toggler-icon{
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 115, 171, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")!important;
}

.form-control{
	box-shadow: 0px 0px 10px #0000001A;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 1; /* Firefox */
			font-weight: 300;
			font-size: 16px;
			line-height: 21px;
			letter-spacing: 0;
			color: #0073AB;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
			font-weight: 300;
			font-size: 16px;
			line-height: 21px;
			letter-spacing: 0;
			color: #0073AB;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
			font-weight: 300;
			font-size: 16px;
			line-height: 21px;
			letter-spacing: 0;
			color: #0073AB;
}

select.customDropdown::-ms-expand {
	font-weight: 300;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #0073AB;
}

select.customDropdown {
	font-weight: 300;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0;
	color: #0073AB;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
   display: block;
   background-color: #0073AB;
   height: 2px;
   width: 25px;
   margin-top: 4px;
   margin-bottom: 4px;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   position: relative;
   left: 0;
   opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
   -webkit-transition: transform .35s ease-in-out;
   -moz-transition: transform .35s ease-in-out;
   -o-transition: transform .35s ease-in-out;
   transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
    background-color: white;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
    background-color: white;
}