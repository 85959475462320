
.titleMaps{
	font-weight: bold;
	font-size: 3vw;
	line-height: 80px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.globeSpacing{
	margin-top: 20px;
}

.titleSpacing{
	z-index: -100;
	margin-top: 15vh;
}

.refDownArrows{
	position: absolute;
	bottom: 65px;
	left: 50%;
}

.refDownArrows > img{
	max-height: 33px;
	transform: translateX(50%);
}

.refDownArrowsGlobalMobile{
	position: absolute;
	/*bottom: 65px;*/
	left: 50%;
	transform: translateX(-100%);
}

.refDownArrowsGlobalMobile > img{
	max-height: 27px;
	transform: translateX(50%);
}

.fixed {
    /*position: fixed;*/
	width: 100vw; /* viewport width */
	height: 100vh; /* viewport height */
	overflow-y: scroll;
}
.inner {
   	min-height: 100%;
}
.siteWrapper {
	width:100%;
	height:100%;
	position:relative;
}

.mapTooltip {
	/*min-height: 150px;
    max-height: 150px;*/
    min-width: 250px;
    max-width: 250px;
    margin: 10px;
    box-shadow: 0px 0px 10px #0000001A;
    z-index: 100;
}

.mapTooltip.blue{
	background-color: #00B4EA;
}
.mapTooltip.purple{
	background-color: #A76BF8;
}
.mapTooltip.red{
	background-color: #ED6546
}
.mapTooltip.bordeau{
	background-color: #c5253d
}
.mapTooltip.yellow{
	background-color: #fbb600
}

.mapTooltipText{
	font-family: 'Segoe UI Local', 'Segoe UI Web (West European)';
    font-weight: 200;
    font-size: 0.9rem;
    line-height: 25px;
    letter-spacing: 0;
    color: white;
    padding: 5px;
}

.mapTooltipTitle{
	font-family: 'Segoe UI Local', 'Segoe UI Web (West European)';
    font-weight: bold;
    font-size: 1rem;
    line-height: 25px;
    letter-spacing: 0;
    color: white;
    padding: 5px;
}

@media only screen and (max-width: 991px){
	.refDownArrows > img{
		max-height: 20px;
	}
	
}

@media only screen and (max-width: 1025px){
	.titleMobile{
		font-weight: 800;
		font-size: 1.875rem;
		line-height: 45px;
		letter-spacing: 0;
		color: #0073AB;
	}
	.legend{
		font-weight: 600;
		font-size: 1rem;
		line-height: 35px;
		letter-spacing: 0;
		display: inline-block;
	}
	.legend.red{
		color: #ED6546;
	}
	.legend.purple{
		color: #A76BF8;
	}
	.legend.blue{
		color: #00B4EA;
	}
	.legend.yellow{
		color: #fbb600;
	}
	.legend.bordeau{
		color: #c5253d;
	}
	.mark{
		width: 30px;
		height: 14px;
		border-radius: 35px;
	}
	.mark.red{
		background: #ED6546 0% 0% no-repeat padding-box;
		border: 1px solid #ED6546;
	}
	.mark.purple{
		background: #A76BF8 0% 0% no-repeat padding-box;
		border: 1px solid #A76BF8;
	}
	.mark.blue{
		background: #00B4EA 0% 0% no-repeat padding-box;
		border: 1px solid #00B4EA;	
	}
	.mark.bordeau{
		background: #c5253d 0% 0% no-repeat padding-box;
		border: 1px solid #c5253d;	
	}
	.mark.yellow{
		background: #fbb600 0% 0% no-repeat padding-box;
		border: 1px solid #fbb600;	
	}
	.refDownArrows{
		position: absolute;
		bottom: 25px;
		left: 50%;
		transform: translateX(-100%);
	}
}