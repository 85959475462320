g[id^="comp"] > ellipse:hover{
	/*fill: #0073AB;
	stroke: #0073AB;
	stroke-width: 8;*/
}

.purpleDot{fill:#a76bf8;}
.blueDot{fill:#00b4ea;}
.navyDot{fill:#1580a0;}
.redDot{fill:#ed6546;}
.whiteDot{fill:white;}
.yellowDot{fill: #fbb600;}
.bordeauDot{fill: #c5253d;}
.greenDot{fill: #64AB80;}