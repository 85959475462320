.container{
	display: inline-block;
}

.axis{
	width: 100%;
}

.dot{
	background-image: url(../images/bluedot.svg);
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
    background-repeat: no-repeat;
    /*background-size: 60%;
    bottom: 38px;
    position: relative;*/
    padding: 20px;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

.year{
	position: relative;
    bottom: -40px;
    transform: translateX(-50%);
    font-weight: bold;
	font-size: 1.0625rem;
	line-height: 22px;
	letter-spacing: 0;
	color: #0073AB;
}

.subtitle{
	font-weight: bold;
	font-size: 1.25rem;
	line-height: 30px;
	letter-spacing: 0;
	color: #0073AB;
}

.descr{
	font-weight: bold;
	font-size: 0.9375rem!important;
	line-height: 20px;
	letter-spacing: 0;
	color: #1F2243;
}

.img{
	max-width: 100%;
}

.arrow{
	background-image: url(../images/timeline-next.svg);
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
    background-repeat: no-repeat;
    width: 20px;
	height: 20px;
	padding: 13px;
    position: relative;
    top: 5px;
    cursor: pointer;
}

.arrowReverse{
	background-image: url(../images/timeline-next.svg);
	-webkit-background-size: cover;
  	-moz-background-size: cover;
  	-o-background-size: cover;
  	background-size: cover;
    background-repeat: no-repeat;
    width: 20px;
	height: 20px;
	padding: 13px;
    position: relative;
    top: 5px;
    cursor: pointer;
    transform: rotate(180deg);
}

.line{
  background: linear-gradient(#0073ab, #0073ab) no-repeat center/2px 100%;
  padding-bottom: 20px;
  transform: translateY(-100%);
}

.lineData{
  transform: translateY(-100%);
  padding-bottom: 41px;
  color: #0073ab;
}

.round{
	position: relative;
	bottom: 94px;
	background: #0073ab;
	width: 50px;
	height: 50px;
	border-radius: 50px;
}

.imgoverlay{
	position: relative;
	bottom: 141px;
	left: -1px;
	width: 30px;
	/*height: 30px;
	border-radius: 50px;*/
	transform: translateX(35%) translateY(25%);
}