
.containerHeight{
	/*min-height: calc(100vh - 87px);*/ /*banner & margin-top*/
	min-height: 100vh;
}

.logoContainer{
	min-height: 184px;
	max-height: 184px;
	max-width: 100%;
	margin-bottom: 20px;
	display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoCol{
	max-height: auto;
	max-width: 132px;
}

.logoColDrive{
	max-height: auto;
	max-width: 80%;
}

.logoOmco{
	transform: translateY(-50%);
}

.column1{
	background-image: url(../images/aero721x462.jpg);
	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
	/*opacity: 0.6;*/
	cursor: pointer;
}

.column1:hover{
	opacity: 0.8;
}

.column2{
	background-image: url(../images/automotive721x462.jpg);
	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
	background-position: center center;
	/*opacity: 0.6;*/
	background-repeat: no-repeat;
    background-size: cover;
    /*animation-name: leaves;
  	animation-duration: 1s;*/
  	/*animation-iteration-count: infinite;*/
}

.col2{
	cursor: pointer;
}

.col2:hover{
	opacity: 0.8;
}

.becky{
	position: absolute;
    top: 0;
    left: 0;
    z-index: -100;
}

.column3{
	background-image:url(../images/omco_721x462.jpg);
	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
	background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
	background-position: center center;	
	/*opacity: 0.75;*/
	background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
}

.column3:hover{
	opacity: 0.8;
}

.arrows{
	position: absolute;
	top: 90%;
	/*transform: translateY(-50%);*/
	transform: translateX(-50%);
}

.arrowsMiddle{
	position: absolute;
	top: 90%;
	transform: translateX(-50%);
}

.hideme{
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	overflow: hidden;
}

.groupDetails{
	font-weight: 400;
	font-size: 30px;
	line-height: 35px;
	letter-spacing: 0;
	color: #FFFFFF;
	text-align: center;
	margin-left: 6%;
	margin-right: 6%;
}

@media only screen and (max-width: 791px){
	nav {direction: rtl;}
	.logoContainer{
		min-height: 150px;
		margin-bottom: 0px;
	}
	.logoOmco{
		transform: translateY(0%);
	}
	.groupDetails{
		margin-bottom: 60px;
	}
	.arrows{
		position: relative;
		top: 0px;
		transform: translateX(0%);
	}
	.logoColDrive{
		max-width: 260px;
	}
	.hideme{
		display: none;
	}
	.containerHeight{
		min-height: 100vh;
	}
	.arrowsMiddle{
		position: absolute;
		top: 70%;
		transform: translateX(-50%) scale(0.5);
	}
}

@media only screen and (max-width: 771px){
	.groupDetails{
		display: none;
	}
	.arrows{
		display: none;
	}
	.logoCol{
		transform: scale(0.7);
	}
	.logoColDrive{
		transform: scale(0.7);
	}
	.column1{
		background: linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(167, 107, 248, 100%)), url(../images/industry72x462.jpg);
		background: -webkit-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(167, 107, 248, 100%)), url(../images/industry72x462.jpg);
		background: -moz-linear-gradient(242deg, rgba(12, 16, 51, 0%), rgba(167, 107, 248, 100%)), url(../images/industry72x462.jpg);
		background-position: center center;
		background-size: cover;
    	background-repeat: no-repeat;
	}
	.column2{
		background: linear-gradient(237deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/automotive721x462.jpg);
		background: -webkit-linear-gradient(237deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/automotive721x462.jpg);
		background: -moz-linear-gradient(237deg, rgba(12, 16, 51, 0%), rgba(237, 101, 70, 100%)), url(../images/automotive721x462.jpg);
		background-position: center center;
		background-size: cover;
    	background-repeat: no-repeat;
	}
	.column3{
		background: linear-gradient(241deg, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
		background: -webkit-linear-gradient(241deg, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
		background: -moz-linear-gradient(241deg, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
		background-position: center center;
		background-size: cover;
    	background-repeat: no-repeat;
	}

}

.hoverImg:hover .child {
	transform: scale(1.1) !important;
}
.hoverImg{
	overflow: hidden;
	padding: 0 !important;
}
.child {
	background-repeat: no-repeat;
	-webkit-transition: all .5s;
	-moz-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}
.h_100{
	height: 100% !important;
}