.topMargin{
	margin-top: 100px;
}

#reactgooglegraph-1 > div > div:nth-child(1) > div > svg > g:nth-child(3) > rect{
	opacity: 0;
}

.downArrows{
	position: absolute;
	bottom: 25px;
	right: 50%;
	max-height: 33px;
	transform: translateX(50%);
}

.downArrows > img{
	max-height: 33px;
}

.therg{
	color:white;
}

.chartWrap {
    position: relative;
    margin-top:30px;
    padding-bottom: 400px;
    height: 0;
    overflow:hidden;
}
.chart {
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height:100%;
}

@media only screen and (max-width: 1024px) {
	.timeline{
		display: none!important;
	}
	.yearMobile{
		background-color: #ED6546;
		font-weight: 800;
		font-size: 30px;
		line-height: 40px;
		letter-spacing: 0;
		color: white;
		border-radius: 35px;
		padding-left: 30px;
		padding-right: 30px;
	}
	.backArrow{
		background-color: #ED6546;
		border-radius: 35px;
		max-width: 10%;
	    margin-left: 10%;
	    margin-right: 10%;
	}
	.backArrow > svg{
		height: 22px;
	}
	.nextArrow{
		background-color: #ED6546;
		border-radius: 35px;
	}
	.graph{
		border-bottom: 2px solid #0C1033; 
		margin-left: 30px;
		margin-right: 30px;
	}
	.column1{
		background-color: #A76BF8;
	}
	.column2{
		background-color: #ED6546;
	}
	.column3{
		background-color: #00B4EA;
	}
	.line1{
		background: linear-gradient(#A76BF8, #A76BF8) no-repeat center/2px 100%;
		padding-bottom: 20px;
	    transform: translateY(-100%);
	}
	.line1Data{
		transform: translateY(-100%);
    	padding-bottom: 41px;
    	color: #A76BF8;
	}
	.line2{
		background: linear-gradient(#ED6546, #ED6546) no-repeat center/2px 100%;
		padding-bottom: 20px;
	    transform: translateY(-100%);
	}
	.line2Data{
		transform: translateY(-100%);
    	padding-bottom: 41px;
    	color: #ED6546;
	}
	.line3{
		background: linear-gradient(#00B4EA, #00B4EA) no-repeat center/2px 100%;
		padding-bottom: 20px;
	    transform: translateY(-100%);
	}
	.line3Data{
		transform: translateY(-100%);
    	padding-bottom: 41px;
    	color: #00B4EA;
	}
}