.navMinHeight{
	min-height: 56px;
}

.navbarBrand{
	margin-left: 9%;
}

.navItem{
	font-weight: 400;
	/*font-size: 30px;*/
	font-size: 1.875rem;
	line-height: 35px;
	letter-spacing: 0;
	color: #0C1033;
}

.navBar{
	margin-right: 25%;
}

.logo{
	/*max-width: 360px;*/
	max-width: 80%;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 300px;
}

.rowStyle{
	margin: 0px;
}

.navbarNavMobile{
	background-color: #0073AB;

}

@media only screen and (max-width: 1440px) and (min-width: 1200px){
	.navItem{
		font-size: 1.6rem;
	}
	.navBar{
		margin-right: 5%;
	}
}

@media only screen and (max-width: 1200px) and (min-width: 991px){
	.navItem{
		font-size: 1.3rem;
	}
	.navBar{
		margin-right: 0%;
	}
}

@media only screen and (max-width: 1025px) and (min-width: 991px){
	.navbarBrand{
		margin-left: 0px!important;
	}
	.logo{
		transform: scale(0.9);
	}
}

@media only screen and (max-width: 1025px){
	.navbarBrand{
		margin-left: 0px!important;
	}
	.logo{
		max-width: 180px;
		transform: scale(0.9);
		padding:0px;
	}
	.rowStyle{
		margin: 0px;
	}
	.colStyle{
		padding: 0px;
	}
	.navItemMobile{
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 30px;
		letter-spacing: 0;
		color: white;
	}
}