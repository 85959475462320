.white{
	color: white;
}

.sectionBackgroundDark{
	background: transparent linear-gradient(264deg, #0C1033 0%, #0073AB 100%) 0% 0% no-repeat padding-box;
}

.sectionBackgroundWhite{
	background: #FFFFFF 0% 0% no-repeat padding-box;
}

.footerContent{
	font-weight: 400;
	/*font-size: 20px;*/
	font-size: 1.25rem;
	line-height: 27px;
	letter-spacing: 0;
	color: #FFFFFF;
}

.buttonText{
	font-weight: bold;
	/*font-size: 20px;*/
	font-size: 1.25rem;
	line-height: 27px;
	letter-spacing: 0;
	color: #0073AB;
	text-transform: uppercase;
	outline: 5px auto -webkit-focus-ring-color;
}

.buttonul{
	padding: 5px 15px 5px 15px;
	background-color: white;
}
.buttonul:hover{
	color: white;
	background-color: #0073AB;
}

.buttonulReverse{
	padding: 5px 15px 5px 15px;
	background-color: #0073AB;
	color: white;
}
.buttonulReverse:hover{
	color: #0073AB;
	background-color: white;
}

.footerWrapper{
	margin-left: 10%;
	margin-right: 10%;
}

.contentBlock{
	display: block;
	margin-right: auto;
	margin-left: auto;
}

.link{
	color: white;
	text-decoration: none;
}
.link:hover{
	color: white;
}

@media only screen and (max-width: 600px) {
	.toColumn{
		flex-direction: column;
		align-items: center!important;
	}
	.footerWrapper{
		text-align: center;
	}
	.companyName{
		font-weight: 600;
		font-size: 0.9375rem;
		line-height: 20px;
		letter-spacing: 0;
		color: white;
	}	
	.companyAddress{
		font-weight: 400;
		font-size: 0.9375rem;
		line-height: 20px;
		letter-spacing: 0;
		color: white;
	}
	.companyAddress:hover{
		color: white;
		text-decoration: none;
	}
	.buttonText{
		font-size: 0.8125rem;
		padding-left: 10px;
		padding-right: 10px;
		background-color: white;
		box-shadow: 0px 0px 10px #00000041;
	}
}