/*body {
  margin: 0;
  min-height: 100vh;
  background: #000;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*margin: 10vmin;*/
  overflow: hidden;
  /*transform: skew(5deg);*/
}
.container .card {
  flex: 1;
  transition: all 1s ease-in-out;
  height: 100vh;
  position: relative;
  cursor: pointer;
}
.container .card .card__head {
  color: black;
  /*background: rgba(255, 30, 173, 0.75);*/
  transition: all 0.5s ease-in-out;
  padding: 0.5em;
  min-width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 1em;
  white-space: nowrap;
}

.container .card:hover .card__head img {
  /*transform: rotate(0deg);*/
  max-width: 25%;
  /*transform-origin: 0% 0%;*/
} 

.container .card:hover {
  flex-grow: 10;
}
.container .card:hover img {
  filter: grayscale(0);
}
.container .card:hover .card__head {
  text-align: center;
  /*top: calc(100% - 2em);*/
  color: white;
  background: rgba(0, 0, 0, 0.5);
  font-size: 2em;
  /*transform: rotate(0deg) skew(-5deg);*/
}
.container .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 1s ease-in-out;
  /*filter: grayscale(100%);*/
}
.container .card:not(:nth-child(5)) {
  /*margin-right: 1em;*/
}

.logo{
	max-width: 50%;
	/*transform: rotate(0deg);*/
}

.hoverino{
  position: fixed;
  display: block;
  width: 60%!important;
  height: 20%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: auto;
  margin-left: auto;
  /*background-color: rgba(0,0,0,0.5);*/
  z-index: 2;
}

.arrowHead, .arrowHeadMobile{
	background-image: url(../images/down-arrows.png);
}

.arrowHeadRed, .arrowHeadRedMobile{
	background-image: url(../images/down-arrows-red.png);
}

.arrowHeadBlue, .arrowHeadBlueMobile{
	background-image: url(../images/down-arrows-blue.png);
}

.arrowHeadYellow, .arrowHeadYellowMobile{
  background-image: url(../images/down-arrows-yellow.png);
}

.arrowHeadBordeau, .arrowHeadBordeauMobile{
  background-image: url(../images/down-arrows-bordeau.png);
}

.arrowHead, .arrowHeadRed, .arrowHeadBlue, .arrowHeadBordeau,
.arrowHeadYellow{
	background-repeat: no-repeat;
	position: absolute;
    top: 90%;
    left: 50%;
    min-width: 45px;
    min-height: 45px;
    transform: translateX(-50%) scale(0.7);
}

.vvv1{
	background-image: url(../images/AirplaneRender8K_72dpi.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/AirplaneRender8K_72dpi.jpg);
	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/AirplaneRender8K_72dpi.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/AirplaneRender8K_72dpi.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/AirplaneRender8K_72dpi.jpg);
  width: 100%;
	background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  min-height: 100%;
}

.vvv2{
	background-image: url(../images/automotive721x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 22%;
  min-height: 100%;
}

.vvv3{
	background-image: url(../images/industry72x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  width: 100%;
	background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;	
  min-height: 100%;
}

.vvv4{
	background-image: url(../images/rail_721x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  width: 100%;
	background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;	
  min-height: 100%;
}

.vvv5{
	background-image: url(../images/omco_721x462.jpg);
	background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
	background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  width: 100%;
	background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;	
  min-height: 100%;
}

.mobileRow1{
  min-height: calc((100vh - 56px) / 4);
  max-width: 100vw;
  background-image: url(../images/aero721x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/aero721x462.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
}

.mobileRow2{
  min-height: calc((100vh - 56px) / 4);
  max-width: 100vw;
  background-image: url(../images/automotive721x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/automotive721x462.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
}

.mobileRow3{
  min-height: calc((100vh - 56px) / 4);
  max-width: 100vw;
  background-image: url(../images/industry72x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/industry72x462.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
}

.mobileRow4{
  min-height: calc((100vh - 56px) / 4);
  max-width: 100vw;
  background-image: url(../images/rail_721x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/rail_721x462.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
}

.mobileRow5{
  min-height: calc((100vh - 56px) / 4);
  max-width: 100vw;
  background-image: url(../images/omco_721x462.jpg);
  background: linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  background: -webkit-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  background: -moz-linear-gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  -ms-filter: progid:DXImageTransform.Microsoft.Gradient(to top right, rgba(12, 16, 51, 0%), rgba(0, 115, 171, 100%)), url(../images/omco_721x462.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center; 
}

.arrowHeadMobile, .arrowHeadRedMobile, .arrowHeadBlueMobile, .arrowHeadYellowMobile, .arrowHeadBordeauMobile{
  background-repeat: no-repeat;
  /*position: relative;
    top: 60px;
    left: 50%;*/
    min-width: 42px;
    min-height: 42px;
    max-width: 42px;
    max-height: 42px;
    transform: scale(0.7);
    margin-top: 10px;
}

.logoMobile{
  max-width: 15%;
  margin-top: 10px;
}

@media only screen and (max-width: 991px){
  .arrowHeadMobile, .arrowHeadRedMobile, .arrowHeadBlueMobile, .arrowHeadYellowMobile, .arrowHeadBordeauMobile{
    min-height: 40px;
    max-height: 40px;
  }
  
}